import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { appSettings } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MentorService {

  baseUrl: string = appSettings.authUrl;
  lessonUrl = appSettings.lessonUrl;
  mentorsUrl = appSettings.mentorsUrl;

  constructor(private http: HttpClient) {
  }

  getAllMentor(pageNumber, filterString?) {
    var url = this.baseUrl + "Mentors/GetAllMentors?PageNumber=" + pageNumber;
    console.log("filter", filterString);
    if (filterString) {
      url += "&Name=" + filterString;
    }
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }


  getAllMentorv2(pageNumber, filterString?, isNftMentor?, isDataMentor?) {
    var url = this.mentorsUrl + "Mentor?PageNumber=" + pageNumber + "&PageSize=10";

    if (filterString) {
      url += "&SearchText=" + filterString;
    }

    if (isNftMentor != undefined) {
      url += "&IsNFTMentor=" + isNftMentor.toLowerCase();
    }

    if (isDataMentor != undefined) {
      url += "&IsDataMentor=" + isDataMentor.toLowerCase();
    }

    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  getAllNftMentorv2(pageNumber, filterString?) {
    var url = this.mentorsUrl + "MentorByUrlSlug?PageNumber=" + pageNumber + "&PageSize=10&IsNFTMentor=true";
    console.log("filter", filterString);
    if (filterString) {
      url += "&SearchText=" + filterString;
    }
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  exportAllMentor(filterString?, isNftMentor?, isDataMentor?, exportType = 'view') {
    var url = this.mentorsUrl + "ExportAllMentors?"
    if (filterString) {
      url += "SearchText=" + filterString;
    }
    if (isNftMentor != undefined) {
      url += "&IsNFTMentor=" + isNftMentor.toLowerCase();
    }
    if (isNftMentor != undefined) {
      url += "&IsDataMentor=" + isDataMentor.toLowerCase();
    }
    url += "&exportType=" + exportType;
    return this.http.get(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      }), responseType: 'blob'
    })
  }


  userSkill(userId: any) {
    let url = this.lessonUrl + "UserStats/user-skills/" + userId;
    return this.http.get<any>(url)
  }

  userChallengeSkill(userId: any) {
    let url = this.lessonUrl + "ChallengeSkill/user-challenge-skills/" + userId;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    })
  }

  lessonDetailBySkill(skillLevelCat: any) {
    let url = this.lessonUrl + "Lessons/GetLessonDetailsBySkillIdAndLevel";
    return this.http.post<any>(url, skillLevelCat);
  }

  mentorLessonAnswer(userId, lessonId) {
    let url = this.lessonUrl + "Answers/mentor-lesson-answers/" + userId + "/" + lessonId;
    return this.http.post<any>(url, {}, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  mentorChallengeAnswer(userId, challengeId) {
    let url = this.lessonUrl + "Answers/mentor-challenge-answers/" + userId + "/" + challengeId;
    return this.http.post<any>(url, {}, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  updateAnswer(newAnswer) {
    let url = this.lessonUrl + "Lessons/update-section-answer";
    return this.http.post<any>(url, newAnswer, {
      headers: new HttpHeaders({
        "x-api-version": "2"
      })
    });
  }

  updateMentor(data) {
    var url = this.baseUrl + "Mentors/UpdateMentor";
    return this.http.post<any>(url, data);
  }

  updateMentorv2(data) {
    var url = this.mentorsUrl + "mentor";
    return this.http.put<any>(url, data);
  }

  getCountryList() {
    var url = this.mentorsUrl + "Country";
    return this.http.get<any>(url);
  }

  getIndustriesList(hasMentors = false) {
    var url = this.mentorsUrl + "Industry";
    if (hasMentors === true) {
      url += '?HasMentors=true';
    }
    return this.http.get<any>(url);
  }

  getSpecializationsList() {
    var url = this.mentorsUrl + "Specializations";
    return this.http.get<any>(url);
  }

  getSkillsList() {
    var url = this.mentorsUrl + "MentorSkills";
    return this.http.get<any>(url);
  }

  getMentorTypes() {
    var url = this.mentorsUrl + "MentorTypes";
    return this.http.get<any>(url);
  }

  getMentorAvailability() {
    var url = this.mentorsUrl + "MentorAvailability";
    return this.http.get<any>(url);
  }

  getNftMentorCategories() {
    var url = this.mentorsUrl + "NftMentorCategory";
    return this.http.get<any>(url);
  }

  getMentorDetails(userId) {
    var url = this.mentorsUrl + "mentor/" + userId;
    return this.http.get<any>(url);
  }

  getGlobalBlogArticles() {
    var url = this.mentorsUrl + "GlobalBlogArticles/";
    return this.http.get<any>(url);
  }

  addGlobalBlogArticles(data) {
    var url = this.mentorsUrl + "GlobalBlogArticles";
    return this.http.post<any>(url, data);
  }

  setFeaturedMentor(mentorId, featured) {
    var url = this.mentorsUrl + "SetFeaturedMentor";
    return this.http.put<any>(url, { mentorId: mentorId, isFeatured: !featured });
  }

  sendRemainder(id) {
    var url = this.mentorsUrl + "MentorRequest/SendMentorRequestLessonsRemainder/" + id + "?isManual=true";
    return this.http.get(url);
  }

  uploadNFTMentorImages(images: File[]) {
    let formData = new FormData();
    images.forEach((image) => formData.append("files", image));
    let headers = new HttpHeaders()
      .append("Content-Disposition", "multipart/form-data")
      .delete("Content-Type");
    var url = this.baseUrl + "Mentors/UploadMentorImage";
    return this.http.post<any>(url, formData, { headers: headers });
  }

  getWebsiteMentors(industryIds?: number[]) {
    var url = this.mentorsUrl + "Mentor?category=m&ForWebsite=true&pageNumber=1&pageSize=3";
    if (industryIds && industryIds.length > 0) {
      for (let id of industryIds) {
        url += '&industryIds=' + id;
      }
    }
    return this.http.get<any>(url);
  }

  getRandomWebsiteMentors() {
    let page = Math.ceil(Math.random() * 20);
    var url = this.mentorsUrl + `Mentor?category=m&ForWebsite=true&pageNumber=${page}&pageSize=3`;
    return this.http.get<any>(url);
  }
}